import Vue from 'vue'
import Vuex from 'vuex'
import Router from "../router/index"
import { userInfo, logout } from '@/api/user'
import { handleTree } from '@/utils/format.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        userInfo: null,
        hasPermis: [],
        isShowLoading: false, // 全局 loading
        // 左侧菜单栏数据
        menuItems: [
            {
                name: '首页', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '首页', // 文本内容
            },
            // {
            //     name: 'other', // 要跳转的路由名称 不是路径
            //     size: 18, // icon大小
            //     type: 'ios-git-commit', // icon类型
            //     text: '单独的路由', // 点击侧边栏跳到一个单独的路由页面，需要提前在 router.js 定义
            // },
            // {
            //     size: 18, // icon大小
            //     type: 'md-arrow-forward', // icon类型
            //     text: '外链',
            //     url: 'https://www.baidu.com',
            //     isExternal: true, // 外链 跳到一个外部的 URL 页面
            // },
            {
                text: '建模管理',
                type: 'ios-paper',
                children: [
                    // {
                    //     size: 18, // icon大小
                    //     type: 'ios-git-commit', // icon类型<
                    //     name: '客户管理',
                    //     text: '客户管理',
                    //     // url: 'https://www.baidu.com',
                    //     // isExternal: true, // 外链 跳到一个外部的 URL 页面
                    // },
                    // {
                    //     size: 18, // icon大小
                    //     type: 'ios-git-commit', //
                    //     name: '区域基础信息',
                    //     text: '区域基础信息',
                    //     // url: 'https://www.baidu.com',
                    //     // isExternal: true, // 外链 跳到一个外部的 URL 页面
                    // },
                    // {
                    //     size: 18, // icon大小
                    //     type: 'ios-git-commit', //
                    //     name: '部门基础信息',
                    //     text: '部门基础信息',
                    //     // url: 'https://www.baidu.com',
                    //     // isExternal: true, // 外链 跳到一个外部的 URL 页面
                    // },
                    // {
                    //     size: 18, // icon大小
                    //     type: 'ios-git-commit', //
                    //     name: '小组基础信息',
                    //     text: '小组基础信息',
                    //     // url: 'https://www.baidu.com',
                    //     // isExternal: true, // 外链 跳到一个外部的 URL 页面
                    // },
                    {
                        size: 18, // icon大小
                        type: 'ios-git-commit', //
                        name: '公司建模',
                        text: '公司建模',
                    },
                    {
                        size: 18, // icon大小
                        type: 'ios-git-commit', //
                        name: '工厂建模',
                        text: '工厂建模',
                    }
                ],
            },
            {
                text: '用户权限管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '用户管理',
                        text: '用户管理',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                    // {
                    //     type: 'ios-git-commit',
                    //     name: '销售退货入库交接',
                    //     text: '销售退货入库交接',
                    //     // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                    //     // hidden: true,
                    // },
                    {
                        type: 'ios-git-commit',
                        name: '权限管理',
                        text: '权限管理',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                    {
                        type: 'ios-git-commit',
                        name: '岗位管理',
                        text: '岗位管理',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                    {
                        type: 'ios-git-commit',
                        name: '流程审批',
                        text: '流程审批',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                    {
                        type: 'ios-git-commit',
                        name: '审批模板',
                        text: '审批模板',
                        // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
                        // hidden: true,
                    },
                ]

            },
            {
                text: '设备资产管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '设备明细',
                        text: '设备明细',

                    },
                    // {
                    //     type: 'ios-git-commit',
                    //     name: '设备资产处理',
                    //     text: '设备资产处理',
                    // },
                    // {
                    //     type: 'ios-git-commit',
                    //     name: '设备基础信息统计',
                    //     text: '设备基础信息统计',
                    // },
                ]

            },
            {
                text: '备件资产管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '备件明细',
                        text: '备件明细',

                    },
                    {
                        type: 'ios-git-commit',
                        name: '备件领用',
                        text: '备件领用',
                    },
                    {
                        type: 'ios-git-commit',
                        name: '备件盘库',
                        text: '备件盘库',
                    },
                    // {
                    //     type: 'ios-git-commit',
                    //     name: '备件基础信息统计',
                    //     text: '备件基础信息统计',
                    // },
                ]

            },
            {
                text: '设备维保管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '设备养护计划',
                        text: '设备养护计划',

                    },
                    {
                        type: 'ios-git-commit',
                        name: '设备养护任务',
                        text: '设备养护任务',
                    },
                    {
                        type: 'ios-git-commit',
                        name: '设备养护类型',
                        text: '设备养护类型',
                    },
                    {
                        type: 'ios-git-commit',
                        name: '设备养护记录',
                        text: '设备养护记录',
                    },
                ]

            },
            {
                text: '设备点检管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '巡检计划',
                        text: '巡检计划',

                    },
                    {
                        type: 'ios-git-commit',
                        name: '巡检任务',
                        text: '巡检任务',
                    },
                    // {
                    //     type: 'ios-git-commit',
                    //     name: '点检过程录入',
                    //     text: '点检过程录入',
                    // },
                    {
                        type: 'ios-git-commit',
                        name: '巡检类型',
                        text: '巡检类型',
                    },
                    {
                        type: 'ios-git-commit',
                        name: '巡检记录查询',
                        text: '巡检记录查询',
                    },
                ]

            },
            {
                text: '设备维修管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '设备维修任务',
                        text: '设备维修任务',

                    },
                    {
                        type: 'ios-git-commit',
                        name: '设备维修计划',
                        text: '设备维修计划',
                    },
                    {
                        type: 'ios-git-commit',
                        name: '设备维修类型',
                        text: '设备维修类型',
                    },
                    // {
                    //     type: 'ios-git-commit',
                    //     name: '维修记录查询',
                    //     text: '维修记录查询',
                    // },
                ]

            },
            {
                text: '物联网管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '采集网关管理',
                        text: '采集网关管理',

                    },
                    {
                        type: 'ios-git-commit',
                        name: '设备采集维护',
                        text: '设备采集维护',
                    },
                    {
                        type: 'ios-git-commit',
                        name: '系统参数维护',
                        text: '系统参数维护',
                    },
                ]

            },
            {
                text: '设备绩效分析',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '设备绩效分析',
                        text: '设备绩效分析',

                    },
                ]

            },
            {
                text: '消息通知维护管理',
                size: 18, // icon大小
                type: 'ios-paper',
                children: [
                    {
                        type: 'ios-git-commit',
                        name: '消息通知管理',
                        text: '消息通知管理',
                    }
                ]

            },
            // {
            //     text: '知识库维护管理',
            //     size: 18, // icon大小
            //     type: 'ios-paper',
            //     children: [
            //         {
            //             type: 'ios-git-commit',
            //             name: '知识内容录入',
            //             text: '知识内容录入',

            //         },
            //         {
            //             type: 'ios-git-commit',
            //             name: '知识内容查询',
            //             text: '知识内容查询',
            //         },
            //         {
            //             type: 'ios-git-commit',
            //             name: '知识板块',
            //             text: '知识板块',
            //         },
            // {
            //     type: 'ios-git-commit',
            //     name: '知识审核',
            //     text: '知识审核',
            // },
        ],
        // 图片预览
        previewImgs: [],
    },
    getters: {
        userInfo: state => {
            return state.userInfo
        },
        preview: state => {
            return state.previewImgs
        },
    },
    mutations: {
        setMenus(state, items) {
            state.menuItems = [...items]
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading
        },
        setUSERINFO(state, user) {
            state.userInfo = user
        },
        SET_HASPERMI(state, payload) {
            state.hasPermis = payload
        },
        // 图片预览
        SET_PREVIEW(state, payload) {
            console.log("SET_PREVIEW", payload);

            state.previewImgs = payload
        },
    },
    actions: {
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                userInfo().then((res) => {
                    commit('setUSERINFO', res.data)
                    commit('SET_HASPERMI', res.data.buttonpermis)
                    sessionStorage.setItem("user_img", res.data.portrait)
                    resolve(res.data.permis_oth)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GenerateRoutes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                const sdata = JSON.parse(JSON.stringify(payload))
                const rData = JSON.parse(JSON.stringify(payload))
                let menus = handleTree(sdata, 'id', 'parent_id')
                menus.unshift({
                    name: '首页', // 要跳转的路由名称 不是路径
                    type: 'md-home', // icon类型
                })
                const asyncRoutes = formatRouter([{ url: '/page', name: '首页', is_menu: 0, component: '/page' }, ...rData]);
                commit("setMenus", menus)
                // router.addRoutes(dynamicRoutes);
                resolve(asyncRoutes)
            })
        },
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    commit('setUSERINFO', null)
                    Router.push("/login")
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        removeUserInfo({ commit, state }) {
            sessionStorage.clear();
            commit('setUSERINFO', null)
            Router.push("/login")
        }
    }
})

function formatRouter(rData) {
    return rData.filter(r => r.is_menu == 0 && r.url !== '/test').map(r => {
        
        return {
            path: r.url,
            component: (resolve) => require(['@/components/home.vue'], resolve),
            children: [
                {
                    path: '',
                    name: r.name,
                    component: (resolve) => require([`@/components${r.component}/index.vue`], resolve),
                    meta: {
                        fatherName: r.parent_id !== 0 ? rData.find(p => p.id === r.parent_id)?.name : null,
                    }
                }
            ]
        }
    })
}

export default store