<template>
  <div class="query_select">
    <el-select
      v-model="selectValue"
      size="small"
      :multiple="multiple"
      clearable
      :placeholder="plhr"
      @change="selectChange"
    >
      <el-input
        prefix-icon="el-icon-search"
        :placeholder="iptplhr"
        v-model="iptVAlue"
        size="small"
        clearable
        @input="inputChange"
      >
      </el-input>
      <el-option
        v-for="(item, index) in selectList"
        :key="item.id + index"
        :label="item[label]"
        :value="item[value]"
        :disabled="item.disabled"
      >
      
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    selectData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
    plhr: {
      type: String,
      default: "请选择",
    },
    iptplhr: {
      type: String,
      default: "请输入",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectValue: undefined,
      iptVAlue: undefined,
      selectList: [],
    };
  },
  created() {
    this.fliterDate();
  },
  methods: {
    inputChange() {
      setTimeout(this.fliterDate, 200);
    },
    fliterDate() {
      const queryString = this.iptVAlue;
      let restaurants = this.selectData;
      if (queryString) {
        const arr = restaurants.filter(this.nameFilter(queryString));
        this.selectList = arr.length
          ? arr
          : [
              {
                [this.label]: `暂未找到与“${queryString}”相关的内容`,
                [this.value]: "01",
                disabled: true,
              },
            ];
      } else {
        this.selectList = restaurants;
      }
    },
    nameFilter(queryString) {
      return (state) => {
        return (
          state[this.label].toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    selectChange(e){
      this.$emit("select",e)
    }
  },
};
</script>

<style lang="less" scope>
</style>