<template>
    <div class="lay_wrap" ref="layout">
        <Nav :portrait="userInfo?.portrait" />
        <div class="lay_content">
            <div class="user" v-if="userInfo.username">
                <div class="u_top">
                    <img :src="baseURL + userInfo.portrait" class="u_img">
                    <div>
                        <div class="u_name">{{ userInfo.username }}</div>
                        <div class="u_score">可用积分：{{ userInfo.score_number || 0 }}</div>

                    </div>
                </div>
                <!-- <div class="u_gz">
                    <span>关注：{{ 0 }}</span>
                    <span>收藏：{{ 0 }}</span>
                </div> -->
                <div class="u_remack">
                    积分是我们平台的核心资源之一，旨在鼓励积极的互动和贡献。每当您上传文档，您将获得6积分作为回报，认可您的分享精神；而下载文档则会消耗3积分，这确保了资源的合理利用。更值得一提的是，对于被社区认定为精选的文档，上传者还将额外获得4积分奖励，以此表彰高质量内容的贡献者。通过这样的机制，我们希望促进一个健康、活跃的知识共享环境。
                </div>
                <ul class="bom_list">
                    <li class="item">
                        <img src="@/assets/zl.png" alt="" class="icon" />
                        <span>发布文章<span class="num">{{ userInfo.release_count || 0 }}</span></span>
                    </li>
                    <li class="item">
                        <img src="@/assets/jx.png" alt="" class="icon" />
                        <span>精选文章<span class="num">{{
                            userInfo.high_quanlity || 0
                                }}</span></span>
                    </li>
                </ul>
            </div>
            <router-view></router-view>
        </div>
        <We />
    </div>
</template>

<script>
import axios from 'axios'
import { baseURL } from "@/utils/axios"
import Nav from "./components/nav.vue"
import We from "@/components/lead/components/we.vue";
export default {
    components: {
        We,
        Nav
    },
    data() {
        return {
            baseURL,
            userInfo: {},
            token: null
        }
    },
    beforeMount() {
        this.token = localStorage.getItem('Konwledge_token') || sessionStorage.getItem('Konwledge_token')
        if (this.token) {
            this.getUserInfo()
            sessionStorage.setItem('Konwledge_token', this.token)
        }
    },
    methods: {
        getUserInfo() {
            axios({
                method: 'get',
                url: this.baseURL + '/userinfo/',
                headers: {
                    'Authorization': this.token,
                    'content-type': 'application/json'
                }
            }).then(res => {
                this.userInfo = res.data
                this.getUserScore()
            }).catch(() => {
                this.userInfo = null
            })
        },
        getUserScore() {
            axios({
                method: 'get',
                url: this.baseURL + '/v1/maintenance/knowledge/get_score/',
                headers: {
                    'Authorization': this.token,
                    'content-type': 'application/json'
                }
            }).then(({ data }) => {
                this.userInfo = {
                    ...this.userInfo,
                    ...data
                }

            }).catch(() => {

            })
        }
    }
}
</script>

<style lang="less" scope>
.lay_wrap {
    padding-top: 55px;
    height: 100vh;
    overflow-y: scroll;


    .lay_content {
        // min-height: calc(100vh - 65px);
        width: 1140px;
        margin: 0 auto 20px;
        margin-top: 10px;
        position: relative;

        .user {
            width: 280px;
            position: absolute;
            top: 0;
            left: -300px;
            min-width: 220px;
            padding: 15px;
            border-radius: 4px;
            background: #fff;
            margin-bottom: 20px;

            .u_top {
                display: flex;

                .u_img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .u_name {
                    font-size: 20px;
                    font-weight: 600;
                    color: #3a3a3a;
                }

                .u_score {
                    font-size: 12px;
                    color: #5c5b5b;
                }
            }

            .u_remack {
                font-size: 10px;
                color: #3f4741c4;
                padding: 5px;
                border-radius: 4px;
                background-color: rgba(212, 212, 212, 0.5);
            }

            .u_gz {
                display: flex;
                justify-content: space-between;
                line-height: 24px;
                margin: 15px 0;
            }

            .bom_list {
                list-style-type: none;

                .item {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #252933;
                    font-weight: 400;

                    .icon {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                    }

                    .num {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
</style>