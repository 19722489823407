<template>
  <div class="index-vue">
    <!-- 侧边栏 -->
    <aside :class="asideClassName">
      <!-- logo -->
      <div class="logo-c">
        <!-- <img src="../assets/imgs/logo.png" alt="logo" class="logo"> -->
        <p v-if="isShowAsideTitle">
          <span class="logo">资产管理</span>
          <span class="name">{{ form.cust_name }}</span>
        </p>
        <Avatar v-else class="cust_name">{{ form.cust_name }}</Avatar>
      </div>
      <!-- 菜单栏 -->
      <Menu class="menu" ref="asideMenu" theme="dark" width="100%" @on-select="selectMenuCallback" accordion
        :open-names="openMenus" :active-name="currentPage" @on-open-change="menuChange">
        <!-- 动态菜单 -->
        <div v-for="(item, index) in menuItems" :key="index">
          <Submenu :class="isShowAsideTitle ? '' : 'shrink'" v-if="item.children" :name="item.name">
            <template slot="title">
              <Icon :size="18" type="ios-paper" />
              <span v-show="isShowAsideTitle">{{ item.name }}</span>
            </template>
            <div v-for="(subItem, i) in item.children" :key="index + i">
              <MenuItem :class="isShowAsideTitle ? '' : 'shrink'" :name="subItem.name">
              <Icon :size="18" :type="'ios-git-commit'" />
              <span v-show="isShowAsideTitle">{{
                subItem.name
              }}</span>
              </MenuItem>
            </div>
          </Submenu>
          <template v-else>
            <MenuItem :class="isShowAsideTitle ? '' : 'shrink'" :name="item.name">
            <Icon :size="18" :type="item.type ? item.type : 'ios-paper'" />
            <span v-show="isShowAsideTitle">{{ item.name }}</span>
            </MenuItem>
          </template>
        </div>
      </Menu>
    </aside>

    <!-- 右侧部分 -->
    <section class="sec-right">
      <!-- 头部 -->
      <div class="top-c">
        <header>
          <div class="h-left">
            <div class="pointer" @click="isShrinkAside" title="收缩/展开">
              <Icon type="ios-apps" />
            </div>
            <!-- 面包屑功能 -->
            <p class="crumbs">{{ crumbs }}</p>
          </div>
          <el-alert type="warning" close-text="不再提示" show-icon style="max-width: 800px" v-if="showTip"
            @close="closeAlert">
            <p slot="title">
              “{{ form.first_name }}”&nbsp;欢迎登陆！您所绑定的企业&nbsp;“{{
                form.cust_name
              }}”&nbsp;将于{{ time }}后到期,如有需要请联系我们变更套餐。
            </p>
          </el-alert>
          <div class="h-right">
            <!-- 移动端 -->
            <el-popover placement="left" title="移动端" popper-class="popper" width="180" trigger="hover"
              @show="popoverShow">
              <div slot>
                <div style="text-align:center;margin-bottom:5px;">安卓版App</div>
                <div ref="qrCode"></div>
                <div style="text-align:center;margin-top:10px;">微信小程序</div>
                <img style="width:150px;height:150px;text-align:center;" src="../assets/weixin1.jpg">
              </div>
              <img slot="reference" src="../assets/phone.png" style="width:25px;height:auto;" />
            </el-popover>
            <!-- 消息 -->
            <span :class="{ newMsg: msgNum.length, msg: true }" v-if="!msgNum.length">
              <Icon type="ios-notifications-outline" title="查看新消息" />
            </span>
            <Poptip v-model="msg_visible" v-else>
              <span :class="{ newMsg: msgNum.length, msg: true }">
                <Icon type="ios-notifications-outline" title="查看新消息" />
              </span>
              <h3 slot="title" class="msg-title">{{ msgNum.length }}条新消息待查收!</h3>
              <div slot="content">
                <ul class="ul-msg">
                  <li v-for="item in showMsgList" :key="item.id">
                    <Icon type="ios-information-circle-outline" color="#2d8cf0" size="32" />
                    <span class="msg-cont_box">
                      <span class="msg-title">{{ item.msg_title }}</span>
                      <span class="msg-content">{{ item.msg_text }}</span>
                      <span class="msg-time">{{ item.send_time }}</span>
                    </span>
                    <span class="read-c" @click="readMsg(item)">
                      <Icon type="md-checkmark" color="#2d8cf0" />
                    </span>
                  </li>
                  <li class="more-msg">
                    <router-link to="/message">查看更多</router-link>
                  </li>
                  <li class="read-all" @click="readMsg()">
                    <Icon type="md-checkmark-circle-outline" style="margin-right: 10px;" />清空通知
                  </li>
                </ul>
              </div>
            </Poptip>
            <Dropdown trigger="click" @on-click="help" style="margin-left: 20px">
              <div class="pointer" style="color: #666">
                帮助
                <Icon type="md-arrow-dropdown" />
              </div>
              <DropdownMenu slot="list">
                <DropdownItem name="1">意见反馈</DropdownItem>
                <DropdownItem divided name="2">客服电话</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div style="margin-left: 20px; margin-right: 20px; font-weight: 700">
              <span>{{ name }}</span>
            </div>
            <!-- 下拉菜单 -->
            <Dropdown trigger="click" @on-click="userOperate">
              <div class="pointer">
                <Avatar v-if="form.portrait" :src="baseURL + form.portrait" />
                <Avatar v-else icon="ios-person" />
              </div>
              <DropdownMenu slot="list">
                <DropdownItem name="2">基本资料</DropdownItem>
                <DropdownItem divided name="3">退出登陆</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </header>

        <!-- 标签栏 -->
        <div class="div-tags">
          <ul class="ul-c">
            <!-- <li v-if="item.text" v-for="(item, index) in tagsArry" :key="index" :class="{active: isActive(item.name)}" @click="activeTag(index)"> -->
            <li v-for="(item, index) in tagsArry" :key="index" :class="{ active: isActive(item.name) }"
              @click="activeTag(index)">
              <div style="
                  height: 8px;
                  width: 8px;
                  background: #dddddd;
                  border-radius: 50%;
                  margin-left: -5px;
                  margin-right: 5px;
                "></div>
              <a class="li-a">
                {{ item.name }}
              </a>
              <Icon size="16" @click="closeTag(index)" type="md-close" />
            </li>
          </ul>
          <!-- 标签栏相关功能 -->
          <div class="div-icons">
            <div class="refresh-c" @click="reloadPage" title="刷新当前标签页">
              <Icon type="md-refresh" />
            </div>
            <div class="tag-options" title="关闭标签">
              <Dropdown trigger="click" @on-click="closeTags">
                <Icon type="ios-options" />
                <DropdownMenu slot="list">
                  <DropdownItem name="1">关闭其他标签</DropdownItem>
                  <DropdownItem name="2">关闭所有标签</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- 页面主体 -->
      <div class="main-content">
        <div class="view-c">
          <keep-alive :include="keepAliveData">
            <!-- 子页面 -->
            <router-view v-if="isShowRouter" />
          </keep-alive>
        </div>
        <!-- <div style="position: fixed;bottom:10px;left:50%;font-weight:600">2022-2025 © TalkingData杭州赫迈思科技</div> -->
      </div>
    </section>
    <Modal v-model="modal" title="基本信息" width="680px">
      <Row :gutter="16">
        <Col span="12" style="border-right: 1px solid #ccc">
        <div>
          <p v-for="(item, index) in basicInfo" :key="index">
            <Tag style="width: 80px; text-align: center; margin-right: 30px" color="orange">{{ item.label }}</Tag>
            <span style="border-bottom: 1px solid #ccc">{{
              item.value
            }}</span>
          </p>
        </div>
        </Col>
        <Col span="12">
        <Tabs size="small" v-model="activeTab">
          <TabPane label="修改头像" name="0">
            <div style="text-align: center">
              <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="headersObj"
                name="portrait">
                <img :src="`${baseURL}${form.portrait}`" class="avatar" />
              </el-upload>
            </div>
          </TabPane>
          <TabPane label="重置密码" name="1">
            <Form :model="formPsw" :label-width="60" ref="formPsw" :rules="ruleCustom">
              <FormItem label="用户名">
                <Input :value="name" disabled>
                <Icon type="ios-person-outline" slot="prepend"></Icon>
                </Input>
              </FormItem>
              <FormItem label="旧密码" prop="password_old">
                <Input v-model="formPsw.password_old" placeholder="请输入原始密码" type="password">
                <Icon type="ios-call-outline" slot="prepend" />
                </Input>
              </FormItem>
              <FormItem label="新密码" prop="password_new">
                <Input v-model="formPsw.password_new" placeholder="请输入新密码" type="password">
                <Icon type="ios-mail-outline" slot="prepend" />
                </Input>
              </FormItem>
              <FormItem label="确认密码" prop="password_repet">
                <Input v-model="formPsw.password_repet" placeholder="确认新密码" type="password">
                <Icon type="ios-mail-outline" slot="prepend" />
                </Input>
              </FormItem>
            </Form>
          </TabPane>
          <!-- <TabPane label="切换账号" name="2">标签三的内容</TabPane> -->
        </Tabs>
        </Col>
      </Row>
      <div slot="footer">
        <Button style="margin-right: 8px" @click="handleCancel(1)">{{
          activeTab == "1" ? "取消" : "关闭"
        }}</Button>
        <Button v-if="activeTab == '1'" type="primary" @click="handleConfirm">确认</Button>
      </div>
    </Modal>

    <Modal v-model="helpModal" :title="helpTitle">
      <div class="box_wrap" v-if="helpTitle == '意见反馈'">
        <p>尊敬的客户你好!</p>
        <p style="margin: 50px 0">
          感谢您使用本产品,在您使用过程中如发现问题,或有什么新的想法和需求,欢迎您通过邮箱反馈给我们,我们会在第一时间回复你的反馈,再次感谢你的支持！
        </p>
        <p>邮箱地址:<span style="color: tomato">admin@he-mes.com</span></p>
      </div>
      <div class="box_wrap" v-else>
        <p>客服电话 : <span style="font-weight: 600">13758255942</span></p>
        <p style="margin: 50px 0">工作时间:早08:00 - 晚18:00(工作日)</p>
        <p>客服电话提供产品帮助，意见反馈等服务，帮助您更好的了解我们</p>
      </div>
      <div slot="footer">
        <Button type="primary" @click="helpModal = false">关闭</Button>
      </div>
    </Modal>

    <el-image-viewer v-if="preview.length" :url-list="previewImg"
      :on-close="() => $store.commit('SET_PREVIEW', [])"></el-image-viewer>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapGetters } from "vuex";
import QRCode from "qrcodejs2";
import md5 from "js-md5";
import { time } from "echarts";
import { baseURL } from "@/utils/axios";
export default {
  name: "index",
  components: {
    ElImageViewer
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.formPsw.password_repet !== "") {
          // 对第二个密码框单独验证
          this.$refs.formPsw.validateField("password_repet");
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formPsw.password_new) {
        callback(new Error("两次密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      baseURL,
      ruleCustom: {
        password_new: [{ validator: validatePass, trigger: "blur" }],
        password_repet: [{ validator: validatePassCheck, trigger: "change" }],
        password_old: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
      },
      showTip: false,
      time: null,
      modal: false,
      helpTitle: "意见反馈",
      helpModal: false,
      basicInfo: [],
      activeTab: "0",
      id: "",
      name: "",
      formItem: {
        first_name: "",
        phone: "",
        email: "",
        workshop_id: "",
        position: "",
        portrait: "",
        customer_id: "??",
      },
      formPsw: {
        password_old: undefined,
        password_new: undefined,
        password_rept: undefined,
      },
      form: {},

      // 用于储存页面路径
      paths: {},
      // 当前显示页面
      currentPage: "",
      openMenus: [], // 要打开的菜单名字 name属性
      menuCache: [], // 缓存已经打开的菜单
      isShowRouter: true,
      msgNum: [], // 新消息条数
      msg_visible: false, // 新消息提示框是否显示
      // 标签栏         标签标题     路由名称
      // 数据格式 {text: '首页', name: 'home'}
      // 用于缓存打开的路由 在标签栏上展示
      tagsArry: [],
      // keepAliveData: [],
      isShowAsideTitle: true, // 是否展示侧边栏内容
      main: null, // 页面主要内容区域
      asideClassName: "aside-big", // 控制侧边栏宽度变化
      asideArrowIcons: [], // 缓存侧边栏箭头图标 收缩时用
      // 面包屑
      crumbs: "主页",
      userName: "",
      userId: undefined,
      // 主页路由名称
      home: "首页",
      sideRoute: [],
      uploadUrl: baseURL + "/userportrait/",
      headersObj: {
        Authorization: sessionStorage.getItem("token"),
      },
      getMsgTimer: null,
    };
  },
  created() {
    this.getMessages();
    this.init();
    this.getMsgTimer = setInterval(() => {
      this.getMessages();
    }, 1000 * 60 * 2);
  },
  beforeDestroy() {
    const { getMsgTimer } = this;
    if (getMsgTimer) {
      clearInterval(getMsgTimer);
      this.getMsgTimer = null;
    }
  },
  mounted() {
    // 第一个标签
    this.setOpenMenu();

    this.main = document.querySelector(".sec-right");
    this.asideArrowIcons = document.querySelectorAll(
      "aside .ivu-icon-ios-arrow-down"
    );

    // 监听窗口大小 自动收缩侧边栏
    this.monitorWindowSize();
    this.getUserInfo();
  },
  watch: {
    $route(val, old) {
      // if (val.path !== "/" + this.keepAliveData[0]) {
      //   const name = old.path.replace("/", "");
      //   this.keepAliveData = [name];
      // }
      this.setOpenMenu();
    },
  },
  computed: {
    ...mapGetters(["userInfo", 'preview']),
    previewImg() {
      return this.preview.map(url => baseURL + url)
    },
    // 菜单栏
    menuItems() {
      return [...this.$store.state.menuItems];
    },
    // 需要缓存的路由
    keepAliveData() {
      return this.tagsArry.slice(-3).map((item) => item.path.replace("/", ""));
    },
    // 由于iView的导航菜单比较坑 只能设定一个name参数
    // 所以需要在这定义组件名称和标签栏标题的映射表 有多少个页面就有多少个映射条数
    nameToTitle() {
      const obj = {};
      this.menuItems.forEach((e) => {
        this.processNameToTitle(obj, e);
      });
      return obj;
    },
    showMsgList() {
      return this.msgNum.splice(0, 5)
    }
  },
  // beforeUpdate() {
  //   this.setOpenMenu();
  // },
  methods: {
    closeAlert() {
      sessionStorage.setItem("showHomeTip", "false");
    },
    init() {
      const showTip = sessionStorage.getItem("showHomeTip");
      const cust_info = this.userInfo.cust_info;
      if (cust_info && cust_info[1] == 4 && !showTip) {
        this.showTip = true;
        setTimeout(() => {
          this.showTip = false;
        }, 5000);
        let dateTime =
          new Date(Date.parse(cust_info[0].slice(0, 10))).getTime() -
          new Date().getTime();
        let day =
          parseInt(dateTime / (3600 * 24 * 1000)) > 10
            ? parseInt(dateTime / (3600 * 24 * 1000))
            : "0" + parseInt(dateTime / (3600 * 24 * 1000));
        let hour =
          parseInt((dateTime % (3600 * 24 * 1000)) / (3600 * 1000)) > 10
            ? parseInt((dateTime % (3600 * 24 * 1000)) / (3600 * 1000))
            : "0" + parseInt((dateTime % (3600 * 24 * 1000)) / (3600 * 1000));
        this.time = day + "天" + hour + "时";
      }
    },
    getMessages() {
      this.$http.get("/messages/", {
        params: { pageNum: 1, pageSize: 100, is_read: 0 }
      })
        .then((res) => {
          this.msgNum = res.data.results;
          if (res.data.count) return this.showMsg()
        });
    },
    // 消息通知
    showMsg() {
      const sessTimestamp = sessionStorage.getItem("showMsg");
      const currentTimestamp = new Date().getTime();
      if (sessTimestamp && currentTimestamp - Number(sessTimestamp) < 1000 * 60 * 60 * 24) return;

      this.$Notice.info({
        title: `您有 ${this.msgNum.length} 条新消息,请及时查收！`,
        render(h) {
          return h(
            "Button",
            {
              attrs: {
                type: "info",
                size: "small",
              },
              on: {
                click() {
                  // 当前时间戳
                  const timestamp = new Date().getTime();
                  // 标记已读
                  sessionStorage.setItem("showMsg", timestamp);
                },
              },
            },
            ["不在显示"]
          );
        },
      });
    },
    async readMsg(msg) {
      if (msg) {
        delete msg["send_time"];
        msg.is_read = 1;
        await this.$http.put(`/messages/${msg.id}/`, msg)
      } else {
        const { data } = await this.$http.get("/v1/one_click_read/")
        if (data.code !== 200) return this.$Message.error(data.detail)
        this.$Message.success(data.detail || "全部已读")
        this.msg_visible = false;
      }
      this.getMessages()
    },
    popoverShow() {
      this.$nextTick(() => {
        this.$refs.qrCode.innerHTML = "";
        new QRCode(this.$refs.qrCode, {
          text: baseURL + "/getApp/",
          width: 150,
          height: 150,
          colorDark: "#000000",
          colorLight: "#ffffff",
        });
      });
    },
    setOpenMenu() {
      const { name, path } = this.$route;
      
      this.currentPage = name;
      if (!this.tagsArry.some((item) => item.name == name)) {
        // 如果标签超过8个 则将第一个标签删除
        if (this.tagsArry.length == 8) {
          this.tagsArry.shift();
        }
        this.tagsArry.push({ name, path });
      }
      if (this.$route.meta.fatherName) {
        this.crumbs = this.$route.meta.fatherName + "/" + this.$route.name;
        this.openMenus = [this.$route.meta.fatherName];
        this.$nextTick(() => {
          this.$refs.asideMenu.updateOpened();
        });
      } else {
        this.crumbs = this.$route.name;
        this.openMenus = [];
        this.$nextTick(() => {
          this.$refs.asideMenu.updateOpened();
        });
      }
    },
    getMenus(name) {
      let menus;
      const tagTitle = this.nameToTitle[name];
      for (let i = 0, l = this.menuItems.length; i < l; i++) {
        const item = this.menuItems[i];
        menus = [];
        menus[0] = i;
        if (item.text == tagTitle) {
          return menus;
        }

        if (item.children) {
          for (let j = 0, ll = item.children.length; j < ll; j++) {
            const child = item.children[j];
            menus[1] = i + "-" + j;
            menus.length = 2;
            if (child.text == tagTitle) {
              return menus;
            }

            if (child.children) {
              for (let k = 0, lll = child.children.length; k < lll; k++) {
                const grandson = child.children[k];
                menus[2] = i + "-" + j + "-" + k;
                if (grandson.text == tagTitle) {
                  return menus;
                }
              }
            }
          }
        }
      }
    },

    monitorWindowSize() {
      let w = document.documentElement.clientWidth || document.body.clientWidth;
      if (w < 1300) {
        this.shrinkAside();
      }

      window.onresize = () => {
        // 可视窗口宽度太小 自动收缩侧边栏
        if (
          w < 1300 &&
          this.isShowAsideTitle &&
          w >
          (document.documentElement.clientWidth || document.body.clientWidth)
        ) {
          this.shrinkAside();
        }

        w = document.documentElement.clientWidth || document.body.clientWidth;
      };
    },

    // 判断当前标签页是否激活状态
    isActive(name) {
      return this.$route.name === name;
    },
    // 跳转页面 路由名称和参数
    gotoPage(name, params) {
      this.currentPage = name;
      this.$router.push({ name, params });
    },
    // 选择菜单回调函数
    selectMenuCallback(name) {
      this.gotoPage(name);
      this.expandAside();
      setTimeout(() => {
        this.isShowAsideTitle = true;
      }, 200);
    },
    resetPSD() {
      this.show = false;
      this.formPsw.username = this.name;
      this.title = "修改密码";
    },
    handleConfirm() {
      this.$refs.formPsw.validate((valid) => {
        if (!valid) return;
        let form = {
          username: this.name,
          password_old: md5(this.formPsw.password_old),
          password_new: md5(this.formPsw.password_new),
          password_repet: md5(this.formPsw.password_repet),
        };
        this.$http.put("/reset/", form).then((res) => {
          if (res.data.status && res.data.status !== 200)
            return this.$Message.error("修改失败!" + "  " + res.data.detail);
          this.$Message.success(res.data.detail);
          this.$store.dispatch("LogOut");
        });
      });
    },
    handleCancel(type) {
      this.modal = false;
      this.activeTag = "0";
      this.formItem = {};
      this.$refs.formPsw.resetFields();
      if (type) this.$Message.info("用户取消操作！！");
    },
    getUserInfo() {
      const userInfo = this.userInfo;
      this.form = userInfo;
      this.id = userInfo.id;
      this.name = userInfo.username;
      this.userId = userInfo.id;
      this.basicInfo = [
        { label: "用户名", value: userInfo.username },
        { label: "姓名", value: userInfo.first_name },
        { label: "姓别", value: userInfo.sex == 0 ? "男" : "女" },
        { label: "微信", value: userInfo.wechat_code },
        { label: "邮箱", value: userInfo.email },
        { label: "注册时间", value: userInfo.date_joined },
        { label: "岗位", value: userInfo.position },
        { label: "电话", value: userInfo.phone },
        { label: "部门", value: userInfo.workshop },
        { label: "公司", value: userInfo.cust_name },
        { label: "修改时间", value: userInfo.modify_time },
      ];
      this.uploadUrl = baseURL + "/userportrait/" + userInfo.id + "/";
    },
    // 帮助
    help(name) {
      this.helpModal = true;
      this.helpTitle = name == "1" ? "意见反馈" : "客服电话";
    },
    // 用户操作
    userOperate(name) {
      switch (name) {
        case "2":
          // 基本资料
          this.title = "基本信息";
          this.formItem = this.form;
          this.show = true;
          this.modal = true;
          break;
        case "3":
          // resetTokenAndClearUser()
          this.$confirm("是否要退出?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$store.dispatch("LogOut").then(() => {
                this.$Message.success("退出成功！");
              });
            })
            .catch(() => {
              this.$Message({
                type: "info",
                message: "已取消退出",
              });
            });

          break;
      }
    },
    // 判断
    isShrinkAside() {
      if (this.isShowAsideTitle) {
        this.shrinkAside();
      } else {
        this.expandAside();
      }
    },
    // 收缩
    shrinkAside() {
      for (let i = 0, len = this.asideArrowIcons.length; i < len; i++) {
        this.asideArrowIcons[i].style.display = "none";
      }
      this.isShowAsideTitle = false;
      this.openMenus = [];
      this.$nextTick(() => {
        if (this.$refs.asideMenu) {
          this.$refs.asideMenu.updateOpened();
        }
      });
      setTimeout(() => {
        this.asideClassName = "";
        this.main.style.marginLeft = "90px";
      }, 0);
    },
    // 展开
    expandAside() {
      setTimeout(() => {
        this.isShowAsideTitle = true;
        for (let i = 0, len = this.asideArrowIcons.length; i < len; i++) {
          this.asideArrowIcons[i].style.display = "block";
        }
        this.openMenus = this.menuCache;
        if (this.$refs.asideMenu) {
          this.$refs.asideMenu.updateOpened();
        }
      }, 200);
      this.asideClassName = "aside-big";
      this.main.style.marginLeft = "220px";
    },
    // 刷新当前标签页
    reloadPage() {
      const { name } = this.$route;
      this.$nextTick(() => {
        this.isShowRouter = false;
        this.tagsArry = this.tagsArry.filter((item) => item.name !== name);
        this.$nextTick(() => {
          this.gotoPage(name);
          this.setOpenMenu();
          this.isShowRouter = true;
        });
      });
    },
    // 关闭单个标签
    closeTag(i) {
      let name = this.tagsArry[i].name;
      this.tagsArry.splice(i, 1);
      window.event.stopPropagation();
      // 如果关闭的是当前标签 则激活前一个标签
      // 如果关闭的是第一个标签 则激活后一个标签
      if (this.tagsArry.length) {
        if (this.isActive(name)) {
          if (i != 0) {
            this.gotoPage(this.tagsArry[i - 1].name);
          } else {
            this.gotoPage(this.tagsArry[i].name);
          }
        }
      } else if (name != this.home) {
        // 如果没有标签则跳往首页
        this.gotoPage(this.home);
      } else {
        this.reloadPage();
      }
    },
    // 根据路由名称关闭页面
    closeName(name) {
      for (let i = 0, len = this.tagsArry.length; i < len; i++) {
        if (this.tagsArry[i].name == name) {
          this.closeTag(i);
          break;
        }
      }
    },
    // 批量关闭标签
    closeTags(flag) {
      if (flag == 1) {
        const name = this.$route.name;
        // 关闭其他标签
        this.tagsArry = this.tagsArry.filter((item) => item.name == name);
      } else {
        // 关闭所有标签
        this.tagsArry = [];
        this.gotoPage(this.home);
        // this.reloadPage();
      }
    },
    // 激活标签
    activeTag(i) {
      this.gotoPage(this.tagsArry[i].name);
    },
    // 菜单栏改变事件
    menuChange(data) {
      this.menuCache = data;
    },
    processNameToTitle(obj, data, text) {
      return;
      if (data.name) {
        obj[data.name] = data.text;
        this.paths[data.name] = text ? `${text} / ${data.text}` : data.text;
      }
      if (data.children) {
        data.children.forEach((e) => {
          this.processNameToTitle(
            obj,
            e,
            text ? `${text} / ${data.text}` : data.text
          );
        });
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.portrait) {
        this.$Message.success("头像上传成功！！");
        this.form.portrait = res.portrait;
        this.$store.dispatch("GetInfo").then(() => {
          this.getUserInfo();
        });
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$Message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$Message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.index-vue {
  height: 100%;
  color: #666;
}

/* 侧边栏 */
aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 90px;
  background: #20222a;
  height: 100%;
  transition: width 0.3s;
  overflow: auto;
}

.logo-c {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  height: 88px;
  justify-content: center;
  position: relative;

  .logo {
    font-size: 30px;
    font-weight: bold;
    color: #efa759;
    font-style: oblique;
  }

  .name {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    line-height: 1;
    margin-left: 10px;
  }

  .cust_name {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    background-color: #efa759;
    color: rgba(255, 255, 255, 0.8);

    /deep/ .ivu-avatar-string {
      line-height: 66px;
    }
  }
}

.aside-big {
  width: 220px;
}

/* 主体页面 */
.sec-right {
  height: 100%;
  margin-left: 220px;
  transition: margin-left 0.3s;
  overflow: hidden;
  background: #f0f0f0;
  /* background: #f3f7fd; */
}

/* 主体页面头部 */
header {
  height: 50px;
  border-bottom: none;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  padding-left: 10px;
  font-size: 14px;
}

header .ivu-icon {
  font-size: 24px;
}

.refresh-c {
  margin: 0 30px;
  cursor: pointer;
}

.h-right {
  display: flex;
  align-items: center;

  .app {
    margin-left: 20px;
  }
}

.h-left {
  display: flex;
  align-items: center;
}

.user-img-c img {
  width: 100%;
}

.msg {
  cursor: pointer;
  position: relative;
  margin-left: 10px;
}

.newMsg::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff5722;
  right: 0;
  top: 0;
}

.ivu-poptip {
  .ivu-poptip-popper {
    .ivu-poptip-title>.msg-title {
      text-align: center;
      color: #3a3a3a;
    }

    .ivu-poptip-body {
      .ivu-poptip-body-content {
        .ul-msg {
          &>li {
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding-right: 35px;

            .msg-cont_box {
              display: flex;
              flex-direction: column;
              margin: 0 10px;

              padding: 5px 0;

              .msg-title {
                font-size: 14px;
                font-weight: 600;
                color: #424242;
              }

              .msg-content {
                font-size: 12px;
                color: #666;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 175px;
              }

              .msg-time {
                font-size: 10px;
                color: #9c9c9c;
              }
            }

            .read-c {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              padding: 4px;
              border-radius: 50%;
              border: 1px solid #f0eeee;
            }

            .read-c:hover {
              border-color: #85b6eb;
            }
          }

          .more-msg {
            &>a {
              text-align: center;
              width: 100%;
              line-height: 35px;
              color: #409eff;
            }
          }

          .read-all {
            line-height: 35px;
            justify-content: center;
          }
        }
      }
    }
  }
}





.user-img-c {
  width: 34px;
  height: 34px;
  background: #ddd;
  border-radius: 50%;
  margin: 0 40px;
  overflow: hidden;
}

.tag-options {
  cursor: pointer;
  position: relative;
}

.div-tags {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
}

.div-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  height: 34px;
  width: 160px;
  font-size: 18px;
}

/* 标签栏 */
.ul-c {
  height: 34px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
  width: calc(100% - 160px);
}

.ul-c li {
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 5px 2px 3px;
  border: 1px solid #e6e6e6;
}

a {
  color: #666;
  transition: none;
}

.li-a {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ul-c .ivu-icon {
  margin-left: 6px;
}

.active {
  background: #409eff;
  border: 1px solid #409eff;
}

.active a {
  color: #fff;
}

.active .ivu-icon {
  color: #fff;
}

/* 主要内容区域 */
.main-content {
  height: calc(100% - 88px);
  overflow: hidden;
  padding: 6px;
  padding-right: 0;
  /* overflow-y:scroll */
}

.view-c {
  position: relative;
  height: 100%;
  /* overflow: hidden; */
  overflow-y: scroll;
}

.pointer {
  cursor: pointer;
}

.crumbs {
  margin-left: 10px;
  color: #97a8be;
  cursor: default;
}

.menu-level-3 .ivu-icon {
  font-size: 18px;
}

.shrink {
  text-align: center;
}

.external {
  color: rgba(255, 255, 255, 0.7);
}

.external>i {
  margin-right: 6px;
}

/deep/ .avatar-uploader {
  .el-upload {
    border-radius: 6px;
    margin-top: 20px;
    width: 200px;
    height: 200px;
    cursor: pointer;

    .avatar {
      width: 100%;
      height: 100%;
    }
  }

  .el-upload:hover {
    border: 1px dashed #409eff;
  }
}
</style>