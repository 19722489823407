import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import plugins from './plugins' // plugins
import directive from './directive/index.js'
import 'element-ui/lib/theme-chalk/index.css'
// 导入全局样式
import './assets/css/global.less'
// 导入字体图标
import './assets/fonts/iconfont.css'
import './assets/fonts/iconfont'
// 自适应
// import 'lib-flexible'
// import 'lib-flexible/flexible.js'
// package Pluginl里面
// "postcss-pxtorem": {
//   "rootValue": 192,
//   "propList": [
//     "*"
//   ]
// }
import 'font-awesome/css/font-awesome.css'
import axios from '@/utils/axios'
import { download } from '@/utils/axios'
import methodsCom from '@/utils/methods'
import dataV from '@jiaminghi/data-view'

// import echarts from 'echarts'
import echarts from './utils/echarts.Uijs'
Vue.prototype.$echarts = echarts

// import china from 'echarts/map/json/china.json'
// echarts.registerMap('china', china)

// 引入iView
import iView from 'iview';
import 'iview/dist/styles/iview.css';    // 使用 CSS

// Vue.use(VueRouter);
Vue.use(iView);



// ie
// import 'babel-polyfill'
import promise from 'es6-promise'
promise.polyfill()
Vue.use(dataV)


Vue.use(plugins)
Vue.use(directive)
// 挂载全局组件
import Pagination from "@/views/Pagination/index";
import QuerySelect from "@/views/QuerySelect"
// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('QuerySelect', QuerySelect)

Vue.prototype.$http = axios
Vue.prototype.$methodsCom = methodsCom
Vue.prototype.$download = download

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

