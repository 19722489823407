<template>
  <div class="input-group">
    <input
      required=""
      :type="type"
      name="text"
      :value="value"
      autocomplete="off"
      class="input"
      @input="handleChange"
    />
    <label class="user-label">{{ label }}</label>
    <i class="el-icon-error clear" v-if="value" @click="clear"></i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: undefined,
    },
    label: {
      type: String,
    },
    type: {
      default: "text",
    },
  },
  name: "Hinput",
  data() {
    return {
      text: undefined,
    };
  },
  methods: {
    clear() {
      this.$emit("input", null);
    },
    handleChange(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
<style lang="less" scope>
.input-group {
  position: relative;
  .input {
    border: solid 1.5px #9e9e9e;
    border-radius: 4px;
    background: none;
    padding: 8px 12px;
    font-size: 12px;
    color: #3a3a3a;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
  }
  .clear {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    color: #9e9e9e;
    display: none !important;
  }
  .user-label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #3a3a3a;
    pointer-events: none;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 14px;
  }
  .input:focus,
  input:valid {
    outline: none;
    border: 1.5px solid #1a73e8;
  }

  .input:focus ~ label,
  input:valid ~ label {
    transform: translateY(-130%) scale(0.8);
    padding: 2px 4px;
    color: #2196f3;
    background: #f3f3f3;
  }
  .input:focus ~ .clear {
    display: block !important;
  }
}

.input-group:hover {
  .clear {
    display: block !important;
  }
}
</style>