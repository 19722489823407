<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  mounted() {
    document.getElementById("app").style.display = "block";
    document.getElementById("appLoading").style.display = "none";
    // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
    window.onbeforeunload = function (e) {
      var storage = window.localStorage;
      storage.clear();
    };
  },
};
</script>

<style>
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  /* 滚动条 拖动条 */
  background-color: rgba(46, 45, 45, 0.2);
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  /* 滚动条背景槽 */
  /* background-color: #eee; */
  background-color: transparent;
  border-radius: 6px;
}
</style>
