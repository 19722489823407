// 注册
<template>
  <div class="mainBox">
    <div class="res_form">
      <h2
        v-if="$route.query.code == 1"
        style="text-align: center; margin-bottom: 20px"
      >
        请注册！
      </h2>
      <h2
        v-if="$route.query.code == 2"
        style="text-align: center; margin-bottom: 20px; padding-top: 100px"
      >
        重置密码
      </h2>
      <Form
        ref="ruleForm"
        class="refForm"
        v-if="$route.query.code == 1"
        :label-width="90"
        :model="ruleForm"
        :rules="rules"
      >
        <FormItem prop="cust_name" label="公司名称">
          <Input
            clearable
            v-model="ruleForm.cust_name"
            placeholder="输入公司名称"
          >
          </Input>
        </FormItem>
        <FormItem prop="cust_address" label="公司地址">
          <Input
            clearable
            v-model="ruleForm.cust_address"
            placeholder="输入公司地址"
          >
          </Input>
        </FormItem>
        <FormItem prop="cust_type" label="客户类型">
          <Select v-model="ruleForm.cust_type" placeholder="请选择客户类型">
            <Option value="0" disabled>标准版</Option>
            <Option value="1" disabled>专业版</Option>
            <Option value="2" disabled>企业版</Option>
            <Option value="3">试用版</Option>
          </Select>
        </FormItem>
        <FormItem prop="cust_industry" label="客户行业">
          <Select v-model="ruleForm.cust_industry">
            <Option
              v-for="item in options"
              :value="item.id + ''"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem prop="contact" label="联系人">
          <Input
            clearable
            v-model="ruleForm.contact"
            placeholder="输入客户联系人"
          >
          </Input>
        </FormItem>
        <FormItem prop="phone" label="电话号码">
          <Input clearable v-model="ruleForm.phone" placeholder="输入电话号码">
          </Input>
        </FormItem>
        <FormItem prop="email" label="邮箱">
          <Input clearable v-model="ruleForm.email" placeholder="输入邮箱">
          </Input>
        </FormItem>
        <FormItem prop="captcha" clearable label="验证码">
          <Row>
            <Col span="14">
              <Input
                clearable
                v-model="ruleForm.captcha"
                placeholder="输入验证码"
              >
                <Icon type="ios-leaf-outline" slot="prepend"></Icon>
              </Input>
            </Col>
            <Col span="10">
              <Button
                type="primary"
                style="float: right"
                :disabled="disabled1"
                @click="send1"
                >{{ countDown1 }}</Button
              >
            </Col>
          </Row>
        </FormItem>
        <FormItem>
          <Button style="width: 217px" type="primary" @click="reg"
            >立即注册</Button
          >
          <div>
            <span>已经账号？</span>
            <span style="color: #33b2ff; cursor: pointer" @click="backLogin"
              >立即登录</span
            >
          </div>
        </FormItem>
      </Form>
      <Form
        ref="formInline"
        v-if="$route.query.code == 2"
        :label-width="90"
        :model="formInline"
        :rules="ruleInline"
      >
        <FormItem prop="phone" label="手机号码">
          <Input
            type="text"
            clearable
            v-model="formInline.phone"
            placeholder="输入手机号"
          >
            <Icon type="ios-call-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="password" clearable label="新密码">
          <Input
            type="password"
            clearable
            v-model="formInline.password"
            placeholder="输入密码"
          >
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="psd" clearable label="验证密码">
          <Input
            type="password"
            clearable
            placeholder="再次输入密码"
            v-model="formInline.psd"
          >
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="captcha" clearable label="验证码">
          <Row>
            <Col span="14">
              <Input
                clearable
                v-model="formInline.captcha"
                placeholder="输入验证码"
              >
                <Icon type="ios-leaf-outline" slot="prepend"></Icon>
              </Input>
            </Col>
            <Col span="10">
              <Button
                type="primary"
                style="float: right"
                :disabled="disabled"
                @click="send"
                >{{ countDown }}</Button
              >
            </Col>
          </Row>
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            style="width: 100%"
            @click="handleSubmit('formInline')"
            >确认</Button
          >
        </FormItem>
      </Form>
      <!-- <div style="text-align:center">
        <span>已经账号？</span>
        <span style="color:#33B2FF;cursor: pointer;"
              @click="backLogin">立即登录</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "register",
  data() {
    const validatorPhone = (rule, value, callback) => {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-45-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      let error = [];
      if (!value) {
        callback(new Error("电话号码不能为空！！"));
      }
      if (!reg.test(value)) {
        callback(new Error("电话号码格式有误！！"));
      }
      callback(error);
    };
    const validatorEmail = (rule, value, callback) => {
      const reg =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      // let error = [];
      if (!value) {
        callback(new Error("邮箱不能为空！！"));
      }
      if (!reg.test(value)) {
        callback(new Error("邮箱格式有误！！"));
      }
      // callback(error);
    };
    const validatorPsd = (rule, value, callback) => {
      let error = [];
      if (value !== this.formInline.password) {
        callback(new Error("两次密码不一致！！"));
      }
      callback(error);
    };
    return {
      ruleForm: {
        cust_code: "??",
        cust_name: "",
        cust_address: "",
        cust_type: "3",
        cust_industry: "",
        contact: "",
        phone: "",
        email: "",
        expir_time: "",
        captcha: "",
      },
      rules: {
        cust_name: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        cust_address: [
          { required: true, message: "公司地址不能为空", trigger: "blur" },
        ],
        cust_industry: [
          { required: true, message: "请选择客户行业", trigger: "change" },
        ],
        cust_type: [
          { required: true, message: "请选择客户类型", trigger: "change" },
        ],
        contact: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        captcha: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
          {
            len: 4,
            message: "请输入4个数字字符",
            trigger: "change",
          },
        ],
        phone: [
          { required: true, message: "电话号码不能为空", trigger: "blur" },
        ],
        email: [{ required: true, message: "邮箱不能为空", trigger: "blur" }],
      },
      options: [],
      countDown1: "获取验证码",
      bVerification1: false, // 节流
      disabled1: false,
      formInline: {
        phone: "",
        password: "",
        captcha: "",
      },
      ruleInline: {
        phone: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
        psd: [{ validator: validatorPsd, trigger: "change" }],
        captcha: [{ required: true, message: "请填写验证码", trigger: "blur" }],
      },
      countDown: "获取验证码",
      bVerification: false, // 节流
      disabled: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async send() {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-45-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.formInline.phone))
        return this.$Message.info("请先输入正确的手机号");
      if (this.bVerification) return;
      this.bVerification = true;
      const { data: res } = await this.$http.post("/SmsVerifyCode/", {
        phone: this.formInline.phone,
      });
      // console.log(res);
      if (res.status === 200) {
        this.$Message.info("发送验证码...");
      }
      let countDown = 59;
      let a = 0;
      this.disabled = true;
      const auth_time = setInterval(() => {
        // this.countDown = countDown-- + '秒后重新操作';
        a = countDown--;
        this.countDown = a + "秒后重新操作";
        if (a <= 0) {
          this.bVerification = false;
          this.countDown = "发送验证码";
          this.disabled = false;
          clearInterval(auth_time);
        }
      }, 1000);
    },
    handleSubmit() {
      this.$refs.formInline.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          "/forgotPassword/",
          this.formInline
        );
        if (res.status) return this.$Message.error(err.data.msg);
        this.$Message.success("重置成功！请重新登录");
        this.$router.push("/login");
      });
    },
    getList() {
      this.$http
        .get("/industrys/")
        .then((res) => {
          this.options = res.data;
        })
        .catch((err) => {
          this.$Message.error(err.data.msg);
        });
    },
    async send1() {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-45-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.ruleForm.phone))
        return this.$Message.info("请先输入正确的手机号");
      if (this.bVerification1) return;
      this.bVerification1 = true;
      const { data: res } = await this.$http.post("/SmsVerifyCode/", {
        phone: this.ruleForm.phone,
      });
      if (res.status === 200) {
        this.$Message.info("发送验证码...");
      }
      let countDown = 59;
      let a = 0;
      this.disabled1 = true;
      const auth_time1 = setInterval(() => {
        // this.countDown = countDown-- + '秒后重新操作';
        a = countDown--;
        this.countDown1 = a + "秒后重新操作";
        if (a <= 0) {
          this.bVerification1 = false;
          this.countDown1 = "发送验证码";
          this.disabled1 = false;
          clearInterval(auth_time1);
        }
      }, 1000);
    },
    reg() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var dt = new Date();
          dt.setMonth(dt.getMonth() + 3);
          this.ruleForm.expir_time = dt.toLocaleString().split(" ")[0];
          this.$http.post("/customers/", this.ruleForm).then((res) => {
            if (res.data.status) return;
            this.$Message.success("注册成功！请前往登录");
            this.ruleForm = {};
            this.$router.push("/login");
          });
        }
      });
    },
    backLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.refForm .ivu-form-item {
  margin-bottom: 20px;
}
.mainBox {
  width: 100%;
  height: 100%;
  background: url("../../assets/页面.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.res_form {
  position: absolute;
  width: 25%;
  // top: 25%;
  // left: 25%;
  // padding-right: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -50%);
}
/deep/ .el-input__inner {
  height: 40px;
  min-width: 220px;
}
</style>