<template>
  <div class="login-vue">
    <div style="text-align: center; position: absolute; top: 20px; left: 20px">
      <h3>关注公众号</h3>
      <img src="../../assets/barcode.jpg" alt="" style="width: 150px; height: 150px" />
    </div>
    <div style="text-align: center; position: absolute; top: 220px; left: 20px">
      <h3>手机APP(安卓)</h3>
      <div class="imgWrap">
        <div ref="qrCode"></div>
      </div>
    </div>
    <div style="text-align: center; position: absolute; top: 420px; left: 20px">
      <h3>微信小程序</h3>
      <img src="../../assets/weixin1.jpg" alt="" style="width: 150px; height: 150px" />
    </div>
    <div class="layout-index" id="waves_container">
      <div class="login_wrap">
        <div class="left_form">
          <div class="back_home">
            <router-link to="/"><i class="el-icon-d-arrow-left"></i>&nbsp;&nbsp;返回首页</router-link>
          </div>
          <div class="official_website">
            <a target="_blank" style="color: #2d8cf0" href="https://www.he-mes.com">官网地址&nbsp;&nbsp;<i
                class="el-icon-d-arrow-right"></i></a>
          </div>
          <Select v-if="userList.length > 0" v-model="formLogin.cust_id" placeholder="请选择企业" @on-change="selectChange">
            <Option v-for="item in userList" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
          <Hinput class="ipt_row" v-model="formLogin.phone" label="账号" />
          <Hinput class="ipt_row" v-model="formLogin.password" type="password" label="密码" />
          <div class="row">
            <Hinput class="code_ipt" v-model="formLogin.captcha" label="验证码" />
            <div @click="refreshCode" class="code_img">
              <img :src="imgSrc" alt="" />
            </div>
          </div>
          <Button :loading="isShowLoading" class="submit_btn" type="primary" @click="submit">登陆</Button>
          <p class="account" style="text-align: center">
            <span class="login" @click="register" style="font-size: 14px; color: tomato">免费申请试用</span>
            |
            <span class="login" @click="forgetPwd" style="font-size: 14px">忘记密码?</span>
          </p>
          <div style="margin-top: 15px">
            <el-checkbox v-model="checked">阅读并同意
              <a target="_blank" class="baseColorFt" style="color: orange" href="#/Terms-of-Service">《服务条款》</a>及
              <a target="_blank" class="baseColorFt" style="color: orange" href="#/Privacy-Policy">《隐私协议》</a>
            </el-checkbox>
          </div>
        </div>
        <div class="ri_logo">
          <div class="logo_box">
            <div class="title">HepEAM</div>
            <img class="logo_img" src="@/assets/leadimg/login_logo.png" alt="" />
          </div>
          <p>生产设备及备件车间内全生命周期管理</p>
        </div>
      </div>
    </div>
    <Footer class="layout-footer-center">2022-2025 &copy; 杭州赫迈思科技</Footer>

    <!-- 多账号提示 -->
    <Modal v-model="loginModal" title="当前账号绑定多家企业，请先选择企业！" :styles="{ top: '300px' }" draggable sticky scrollable
      :mask="false">
      <RadioGroup v-model="formLogin.cust_id" vertical>
        <Radio v-for="(item, index) in userList" :key="index" :label="item.id" class="item"
          :class="formLogin.cust_id == item.id ? 'select' : ''">
          <div class="right">
            <img src="../../assets/factory.png" class="img" alt="factory" />
            <span class="name">{{ item.name }}</span>
          </div>
        </Radio>
      </RadioGroup>
      <!-- <div class="item" v-for="(item,index) in userList" :key="index">{{item}}</div> -->
      <div slot="footer">
        <Button style="margin-right: 8px" @click="loginModal = false">取消</Button>
        <Button type="primary" @click="submit">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Hinput from "./components/Hinput.vue";
import QRCode from "qrcodejs2";
import waves from "./js/cwaves.js";
import md5 from "js-md5";
import { login } from "@/api/user";
import { baseURL } from "@/utils/axios"
export default {
  name: "login",
  data() {
    return {
      loginModal: false,
      userList: [],
      formLogin: {
        phone: "",
        password: "",
        captcha: "",
        hashkey: "",
        cust_id: null,
        terminal: "pc",
      },
      imgSrc: "",
      // identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',//随机串内容
      // identifyCode: '',
      // account: 'admin',
      // pwd: '123456',
      isShowLoading: false,
      bg: {},
      checked: true,
    };
  },
  components: { Hinput },
  created() {
    // this.bg.backgroundImage = 'url(' + require('../assets/imgs/bg0' + new Date().getDay() + '.jpg') + ')'
    window.addEventListener("keydown", this.keyDown);
    this.getCode();
  },
  mounted() {
    waves.init("waves_container");
    this.qrcode();
    //   this.identifyCode = ''
    //   this.makeCode(this.identifyCodes, 4)
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  beforeDestroy() {
    waves.removeEvent();
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    qrcode() {
      this.$refs.qrCode.innerHTML = "";
      new QRCode(this.$refs.qrCode, {
        text: baseURL + "/getApp/",
        width: 130,
        height: 130,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.submit(); // 定义的登录方法
      }
    },
    refreshCode() {
      this.getCode();
    },
    getCode() {
      this.$http
        .get("/login/")
        .then((res) => {
          this.imgSrc = baseURL.replace("/api", "") + res.data.image_url;
          this.formLogin.hashkey = res.data.hashkey;
        })
        .catch((rel) => {
          this.$Message.error("获取验证码失败");
        });
    },
    register() {
      // this.dialogFormVisible = true
      this.$router.push({ path: "/register", query: { code: 1 } });
    },
    forgetPwd() {
      this.$router.push({ path: "/register", query: { code: 2 } });
      // this.$Message.info('还没写！莫着急')
    },
    selectChange(e) {
      if (e) this.submit();
    },
    async submit() {
      if (this.checked == false) return this.$Message.info("请先勾选协议！");
      const form = {
        phone: this.formLogin.phone,
        password: md5(this.formLogin.password),
        captcha: this.formLogin.captcha,
        hashkey: this.formLogin.hashkey,
        cust_id: this.formLogin.cust_id,
        terminal: "pc",
      };
      const { data: res } = await login(form);
      if (res.token) {
        sessionStorage.setItem("token", res.token);
        localStorage.setItem("Konwledge_token", res.token);
        this.$router.push("/home");
        this.$Message.success(res.detail);
      } else if (res.status == 205) {
        this.userList = res.detail;
        this.$Message.warning("当前账号绑定多家企业，请先选择企业！");
      } else {
        this.loginModal = false;
        this.getCode();
        this.$Message.error(res.detail);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login:hover {
  color: #2b85e4;
}

.login-vue {
  position: relative;
  height: 100%;
  color: #fff;
}

.layout-index {
  height: 100%;
  width: 100%;
  flex: 1;
  background-image: linear-gradient(70deg, #2061c2 0, #0d0525 100%);

  .login_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(19, 9, 40, 0.16) 0px 36px 36px -12px,
      rgba(19, 9, 40, 0.08) 0px 1px 2px 0px;

    .left_form {
      position: relative;
      width: 350px;
      background-image: linear-gradient(to bottom,
          #f3f3f3 0,
          rgba(255, 255, 255, 0.5) 100%);
      height: 550px;
      padding: 120px 40px 0;

      .back_home {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .official_website {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      ::v-deep .ivu-select {
        margin-bottom: 30px;
        background-color: transparent;
        border: solid 1.5px #1a73e8;
        border-radius: 4px;
        color: #3a3a3a;
        text-align: left;

        .ivu-select-selection {
          background-color: transparent;
          line-height: 37px;
          border: none;
        }

        .ivu-select-placeholder {
          color: #3a3a3a;
        }
      }

      .ipt_row {
        margin-bottom: 30px;
      }

      .row {
        display: flex;
        align-items: center;

        .code_ipt {
          margin-right: 18px;
        }

        .code_img {
          height: 37px;
          border-radius: 2px;
          overflow: hidden;
          cursor: pointer;

          img {
            height: 35px;
            width: 80px;
          }
        }
      }

      .submit_btn {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        letter-spacing: 1.5em;
      }
    }

    .ri_logo {
      width: 390px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background-image: linear-gradient(to top,
          #1e3c72 0%,
          #1e3c72 12%,
          #2a5298 100%);

      .logo_box {
        .title {
          text-align: center;
          font-size: 26px;
          margin-bottom: 20px;
          color: #142760;
          font-weight: 700;
          -webkit-animation-name: breath;
          /* 动画名称 */
          -webkit-animation-duration: 3s;
          /* 动画时长3秒 */
          -webkit-animation-timing-function: ease-in-out;
          /* 动画速度曲线：以低速开始和结束 */
          -webkit-animation-iteration-count: infinite;
          /* 播放次数：无限 */
        }

        @keyframes breath {

          /* 动画开始时的不透明度 */
          from {
            opacity: 0.1;
          }

          /* 动画50% 时的不透明度 */
          50% {
            opacity: 1;
          }

          /* 动画结束时的不透明度 */
          to {
            opacity: 0.1;
          }
        }

        .logo_img {
          width: 245px;
          height: 245px;
        }
      }
    }
  }
}

.login-vue .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.5);
  width: 300px;
  text-align: center;
  border-radius: 10px;
  padding: 30px;
}

.login-vue .ivu-input {
  background-color: transparent;
  color: #fff;
  outline: #fff;
  border-color: #fff;
  height: 50px !important;
}

// .login-vue .ivu-select {
//   background-color: transparent;
//   // color: #fff;
//   // outline: #fff;
//   // border-color: #fff;
//   // height: 50px !important;
//   text-align: left;
// }
// ::v-deep .ivu-select-prefix {
//   width: 32px;
//   height: 100%;
//   text-align: center;
//   padding: 0;
//   z-index: 1;
//   i {
//     font-size: 16px;
//     line-height: 32px;
//     color: #808695;
//   }
// }
// ::v-deep .ivu-select-selected-value {
//   width: 140px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   padding: 0;
// }
// input提示文字颜色
// .login-vue ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
//     color: rgba(255, 255, 255, .8);
// }
// .login-vue :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//     color: rgba(255, 255, 255, .8);
// }
// .login-vue ::-moz-placeholder { /* Mozilla Firefox 19+ */
//     color: rgba(255, 255, 255, .8);
// }
// .login-vue :-ms-input-placeholder { /* Internet Explorer 10-11 */
//     color: rgba(255, 255, 255, .8);
// }

.login-vue .input-c {
  margin: auto;
  width: 200px;
}

.login-vue .error {
  color: red;
  text-align: left;
  margin: 5px auto;
  font-size: 12px;
  padding-left: 30px;
  height: 10px;
}

.login-vue .submit {
  width: 200px;
}

.login-vue .account {
  display: block;
  margin-top: 30px;
}

.login-vue .account span {
  cursor: pointer;
}

.login-vue .ivu-icon {
  color: #eee;
}

.login-vue .ivu-icon-ios-close-circle {
  color: #777;
}

.logintext {
  margin-bottom: 20px;
  line-height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.layout-footer-center {
  background-color: transparent;
  color: #fff;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2%;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;

  a {
    color: #fff;
  }
}

/deep/ .el-checkbox__label {
  color: #fff;
}

.imgWrap {
  width: 150px;
  height: 150px;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}

.ivu-modal {
  .ivu-modal-body {
    .ivu-radio-group {
      width: 100%;

      .item {
        border-radius: 4px;
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
        height: auto;
        line-height: 1;
        cursor: pointer;
        display: flex;
        align-items: center;

        .right {
          flex: 1;
          display: flex;
          align-items: center;

          .img {
            width: 30px;
            height: 30px;
            margin: 0 8px;
          }

          .name {
            flex: 1;
            color: rgb(143, 152, 157);
          }
        }
      }

      .item:hover {

        // background-image: linear-gradient(-225deg, #CBBACC 0%, #2580B3 100%);
        .name {
          color: #3a3a3a !important;
        }
      }

      .select {
        .name {
          color: #3a3a3a !important;
        }

        background-image: linear-gradient(-225deg, #cbbacc 0%, #2580b3 100%);
      }
    }
  }
}
</style>
