import store from '@/store'

export default {
    inserted(el, binding, vnode) {
        const { value } = binding
        // const all_permission = "*:*:*";
        const permissions = store.state.hasPermis
        if (value) {
            if (permissions.indexOf(value) == -1) {
                el.parentNode && el.parentNode.removeChild(el)
            } else {
                new Error(`请设置操作权限标签值`)
            }
        }
    }
}